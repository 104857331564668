<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div>
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Email Sender Name</label>
                                    <InputText id="sender_email_name" placeholder="Email Sender Name" v-model="form.sender_email_name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="sender_email_name" /> 
                                </div>
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Sender Email Address</label>
                                    <InputText id="sender_email_address" placeholder="Email Sender Email Address" v-model="form.sender_email_address"  type="email" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="sender_email_address" /> 
                                </div>
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Phone</label>
                                    <InputText id="phone" placeholder="Phone" v-model="form.phone" min="0" step="1" type="number" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="phone" /> 
                                </div>  
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>Website</label>
                                    <InputText id="website" placeholder="Website" v-model="form.website" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="website" /> 
                                </div>  
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>Registration Number</label>
                                    <InputText id="registration_number" placeholder="Registration Number" v-model="form.registration_number"  class="full-width"/>
                                    <HasError class="p-error" :form="form" field="registration_number" /> 
                                </div>  
                                 <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left" v-if="checkPermission(13,'dropdown')"> 
                                    <label>Country</label>
                                    <Dropdown CountriesLoading @change="getStates($event)" v-model="form.country" :filter="true" :showClear="true" :options="countries" optionLabel="name" optionValue="id" :placeholder="CountriesLoading ? 'Loading...' : 'Select Country'" class="full-width" />
                                    <HasError class="p-error" :form="form" field="country" /> 
                                </div> 
                                <div v-if="form.country > 0 && checkPermission(14,'dropdown')" class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>State</label>
                                    <Dropdown StatesLoading @change="getCities($event)" v-model="form.state" :filter="true" :showClear="true" :options="states" optionLabel="name" optionValue="id" :placeholder="StatesLoading ? 'Loading...' : 'Select State'" class="full-width" />
                                    <HasError class="p-error" :form="form" field="city" /> 
                                </div> 
                                <div v-if="form.state > 0 && checkPermission(15,'dropdown')" class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>City</label>
                                    <Dropdown CitiesLoading v-model="form.city" :filter="true" :showClear="true" :options="cities" optionLabel="name" optionValue="id" :placeholder="CitiesLoading ? 'Loading...' : 'Select City'" class="full-width" />
                                    <HasError class="p-error" :form="form" field="country" /> 
                                </div>   
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Address</label>
                                    <InputText id="address" placeholder="Address"  v-model="form.address" type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="address" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left"> 
                                    <label>Postal Address (Leave empty if same as above)</label>
                                    <InputText id="postal_address" placeholder="Postal Address" v-model="form.postal_address" type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="postal_address" /> 
                                </div> 
                                
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>Postal Code/ZipCode</label>
                                    <InputText id="zipcode" placeholder="ZipCode" v-model="form.zipcode" type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="zipcode" /> 
                                </div> 
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left"> 
                                    <label>About/Description</label>
                                    <Textarea placeholder="Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div>  
                            </div>  
                            <div v-if="form.companyBankAccounts.length">
                                <div v-for="(account,key) in form.companyBankAccounts" :key="key"> 
                                    <Panel class="mb-3 full-width" :header="'Bank Account '+ (key+1) " :toggleable="true"  style="float:left" >  
                                        <div>
                                            <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                <label>Account Title</label>
                                                <InputText id="company_account_title" placeholder="Account Title" v-model="form.companyBankAccounts[key].acc_title" :value="account.acc_title"  type="text" class="full-width"/>
                                            </div> 
                                            <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                <label>Bank Name</label>
                                                <InputText id="company_bank_names" placeholder="Bank Name" v-model="form.companyBankAccounts[key].bank_name" :value="account.bank_name"  type="text" class="full-width"/>
                                            </div>
                                            <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                <label>Branch Code</label>
                                                <InputText id="company_branch_code" placeholder="Branch Code" v-model="form.companyBankAccounts[key].branch_code" :value="account.branch_code"  type="text" class="full-width"/>
                                            </div> 
                                            <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                <label>Account Number</label>
                                                <InputText id="company_account_number" placeholder="Account Number" v-model="form.companyBankAccounts[key].acc_no" :value="account.acc_no"  type="text" class="full-width"/>
                                            </div>
                                            <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                <label>IBAN</label>
                                                <InputText id="company_account_iban" placeholder="IBAN" v-model="form.companyBankAccounts[key].iban" :value="account.iban"  type="text" class="full-width"/>
                                            </div>
                                            <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                <label>Swift Code</label>
                                                <InputText id="company_account_swift_code" placeholder="Swift Code" v-model="form.companyBankAccounts[key].swift_code" :value="account.swift_code"  type="text" class="full-width"/>
                                            </div> 
                                            <div class="col-2 lg:col-2 sm:col-12 mb-2 mt-3" style="float:left">  
                                                <Button @click="form.companyBankAccounts.splice(key,1)" v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete'"  icon="pi pi-trash"  class="p-button-lg p-button-raised p-button-danger mr-2"  style="padding: 8px 20px;"/> 
                                            </div>
                                        </div> 
                                    </Panel> 
                                </div>
                            </div> 
                            <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                <label>Quotation Terms</label>
                                <ckeditor id="editor"  @ready="onReady" :editor="editor"  placeholder="Quotation Terms" v-model="form.quotation_terms" :config="editorConfig1" /> 
                                <!-- <Textarea placeholder="Quotation Terms" v-model="form.quotation_terms" rows="5" cols="30" class="full-width" /> -->
                                <HasError class="p-error" :form="form" field="quotation_terms" /> 
                            </div> 
                            <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                <label>Invoice Terms</label>
                                <ckeditor id="editor"  @ready="onReady" :editor="editor"  placeholder="Invoice Terms" v-model="form.invoice_terms" :config="editorConfig1" /> 
                                <!-- <Textarea placeholder="Invoice Terms" v-model="form.invoice_terms" rows="5" cols="30" class="full-width" /> -->
                                <HasError class="p-error" :form="form" field="invoice_terms" /> 
                            </div> 
                            <div class="col-12 lg:col-12 sm:col-12 mb-12 mt-3 mb-3" style="float:left">
                                <Button @click="addCompanyBankAccountItem()" v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New Contact'" :label="showBtnLabels ? 'Add New Bank Account' : ''" icon="pi pi-plus"  class="p-button-raised p-button-success mt-2 mr-2 float-right" /> 
                            </div>
                        </div> 
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div> 
                                <Panel v-if="logo" class="mb-3" header="Current Logo" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2 profileCardImg"> 
                                        <div>
                                            <img alt="Logo" :src="logo" >
                                        </div>
                                        <div>
                                            <ConfirmPopup group="popup"></ConfirmPopup>
                                            <Button ref="popup" @click="confirmFileDeletion($event)" icon="pi pi-times" label="Remove" class="p-button-danger p-button-raised p-button-md" style="margin-left: .5em" />
                                        </div>   
                                    </div>
                                </Panel>
                                <Panel class="mb-3" :header="module_name+' Logo'" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <FileUpload name="logo" :showUploadButton="false" :customUpload="true" @select="myUploader" :multiple="false" accept="image/*" :maxFileSize="1000000"> </FileUpload> 
                                    </div> 
                                </Panel>  
                                <Panel class="mb-3" header="Company Admin Information" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                        <InputText id="name" placeholder="Name" v-model="form.user_name"  type="text" class="full-width"/>
                                        <HasError class="p-error" :form="form" field="user_name" /> 
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <label><span class="pi pi-star-fill important mr-1"></span>Email</label>
                                        <InputText id="email" placeholder="Email" v-model="form.user_email"  type="email" class="full-width"/>
                                        <HasError class="p-error" :form="form" field="user_email" /> 
                                    </div> 
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <label><span class="pi pi-star-fill important mr-1"></span>Phone</label>
                                        <InputText id="phone" placeholder="Phone" v-model="form.user_phone" min="0" step="1" type="number" class="full-width"/>
                                        <HasError class="p-error" :form="form" field="user_phone" /> 
                                    </div>  
                                </Panel> 
                                <Panel class="mb-3 full-width" header="Units and Measurements" :toggleable="true" style="float:left">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <label>Currency</label>
                                        <MultiSelect id="currency1" CurrenciesLoading v-model="form.currency" :filter="true" :showClear="true" :options="Currencies" optionLabel="name" optionValue="id" :placeholder="CurrencyLoading ? 'Loading...' : 'Select Currencies'" class="full-width" />
                                        <HasError class="p-error" :form="form" field="currency" /> 
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <label>TimeZone</label>
                                        <Dropdown id="timeZone1" TimeZonesLoading v-model="form.timezone" :filter="true" :showClear="true" :options="TimeZones" optionLabel="name" optionValue="id" :placeholder="TimeZoneLoading ? 'Loading...' : 'Select TimeZones'" class="full-width" />
                                        <HasError class="p-error" :form="form" field="timezone" /> 
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                        <label>Date Format</label>
                                        <!-- <AutoComplete v-model="form.date_format" :suggestions="filteredDateFormat" @complete="searchInDateFormat($event)"   :dropdown="true" optionLabel="name" field="id" :placeholder="'Select Date Format'" class="full-width">
                                            <template #item="slotProps">
                                                <div class="country-item">
                                                    <div class="ml-2"><strong>{{slotProps.item.name}}</strong></div>
                                                </div>
                                            </template>
                                        </AutoComplete> -->
                                        <Dropdown id="date_format" v-model="form.date_format" :filter="true" :showClear="true" :options="DateFormats" optionLabel="name" optionValue="id" :placeholder="'Select Date Format'" class="full-width" />
                                        <HasError class="p-error" :form="form" field="date_format" /> 
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                        <label>Time Format</label> 
                                        <Dropdown id="time_format" v-model="form.time_format" :filter="true" :showClear="true" :options="TimeFormats" optionLabel="name" optionValue="id" :placeholder="'Time Fromat'" class="full-width" />
                                        <HasError class="p-error" :form="form" field="time_format" /> 
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                        <label>Distance Unit</label>
                                        <Dropdown id="distance_unit1" DistanceUnitsLoading v-model="form.distance_unit" :filter="true" :showClear="true" :options="DistanceUnits" optionLabel="name" optionValue="id" :placeholder="DistanceUnitLoading ? 'Loading...' : 'Select Distance Unit'" class="full-width" />
                                        <HasError class="p-error" :form="form" field="distance_unit" /> 
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                        <label>Price Rounding</label>
                                        <InputNumber id="price_rounding1" v-model="form.price_rounding" mode="decimal" showButtons :min="0" step="0.1" :max="100"  class="full-width"/> 
                                        <HasError class="p-error" :form="form" field="price_rounding" /> 
                                    </div> 
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <label class="full-width">Set Due Date of Deposited Invoice</label><br>
                                        <InputNumber id="deposited_invoice_due_date_days" v-model="form.deposited_invoice_due_date_days" prefix="Expires in " suffix=" days"   class="half-width"/>  
                                        <HasError class="p-error" :form="form" field="deposited_invoice_due_date_days" /> 
                                        <Dropdown id="deposited_invoice_due_date_var1" v-model="form.deposited_invoice_due_date_var" :showClear="true" :options="InvoiceDueDateVar" optionLabel="name" optionValue="id" placeholder="Select Value "  class="half-width pl-2"   />
                                        <HasError class="p-error" :form="form" field="deposited_invoice_due_date_var" /> 
                                    </div> 
                                    <!-- <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <label class="full-width">Set Due Date of Other Invoice</label><br>
                                        <InputNumber id="other_invoice_due_date_days1" v-model="form.other_invoice_due_date_days" prefix="Expires in " suffix=" days"   class="half-width"/>  
                                        <HasError class="p-error" :form="form" field="other_invoice_due_date_days" /> 
                                        <Dropdown id="other_invoice_due_date_var1" v-model="form.other_invoice_due_date_var" :showClear="true" :options="InvoiceDueDateVar" optionLabel="name" optionValue="id" placeholder="Select Value "  class="half-width"   />
                                        <HasError class="p-error" :form="form" field="other_invoice_due_date_var" /> 
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                        <label>Deposit Percentage</label>
                                        <InputNumber id="deposit_percentage" v-model="form.deposit_percentage" mode="decimal" prefix="%" class="full-width"/> 
                                        <HasError class="p-error" :form="form" field="deposit_percentage" /> 
                                    </div> 
                                    <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                        <label>Deposit Fixed Amount</label>
                                        <InputNumber id="deposit_fixed_amount" v-model="form.deposit_fixed_amount" mode="currency" currency="GBP" locale="en-GB" class="full-width" />
                                        <HasError class="p-error" :form="form" field="deposit_fixed_amount" /> 
                                    </div> 
                                    <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                        <label>Deposit Cut Off Date</label>
                                        <InputNumber id="deposit_cutoff_days" v-model="form.deposit_cutoff_days" prefix="" suffix=" days"   class="full-width"/>  
                                        <HasError class="p-error" :form="form" field="deposit_cutoff_days" /> 
                                    </div> 
                                    <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                        <label>Fixed Route Distance</label>
                                        <InputNumber id="fixed_route_distance" v-model="form.fixed_route_distance" suffix=" mile"  class="full-width"/> 
                                        <HasError class="p-error" :form="form" field="fixed_route_distance" /> 
                                    </div> 
                                    <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                        <label>Calendar Uplift Max Range Distance</label>
                                        <InputNumber id="calendar_uplift_max_range_distance" v-model="form.calendar_uplift_max_range_distance" suffix=" mile"  class="full-width"/> 
                                        <HasError class="p-error" :form="form" field="calendar_uplift_max_range_distance" /> 
                                    </div> 
                                    <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                        <label>Regional Uplift Max Range Distance</label>
                                        <InputNumber id="regional_uplift_max_range_distance" v-model="form.regional_uplift_max_range_distance" suffix=" mile"  class="full-width"/> 
                                        <HasError class="p-error" :form="form" field="regional_uplift_max_range_distance" /> 
                                    </div>  -->
                                    <!-- <div class="col-12 lg:col-12 sm:col-12 mb-2" > 
                                        <label>Supplier Pricing Range Distance</label>
                                        <InputNumber id="supplier_uplift_max_range_distance" v-model="form.supplier_uplift_max_range_distance" suffix=" mile"  class="full-width"/> 
                                        <HasError class="p-error" :form="form" field="supplier_uplift_max_range_distance" /> 
                                    </div>   -->
                                </Panel> 
                            </div>   
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    
</template>
<script> 
import ClassicEditor1 from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    name: '', 
                    sender_email_name:'',
                    sender_email_address:'',
                    user_name:'',
                    user_email:'',
                    user_phone:'',
                    phone:'', 
                    website:'',
                    registration_number:'',
                    country:0,
                    state:0,
                    city:0,
                    zipcode:'',
                    postal_address:'',
                    address: '', 
                    logo: '', 
                    status:1, 
                    description: '', 
                    supplier_uplift_max_range_distance:0,   
                    regional_uplift_max_range_distance:0,   
                    calendar_uplift_max_range_distance:0,   
                    fixed_route_distance:0,   
                    deposit_cutoff_days:0,   
                    deposit_fixed_amount:0,   
                    deposit_percentage:0,   
                    other_invoice_due_date_days:0,
                    deposited_invoice_due_date_days:0,
                    other_invoice_due_date_var:'',   
                    deposited_invoice_due_date_var:'',   
                    price_rounding:0.0,   
                    distance_unit:0,   
                    timezone:0,   
                    date_format:'',
                    time_format:'',
                    currency:[],  
                    companyBankAccounts:[],    
                    quotation_terms:'',
                    invoice_terms:''
                }), 
                editor: ClassicEditor1, 
                onReady:false,
                DateFormats: [
                    {id:'31/12/1999',name:'31/12/1999'},
                    {id:'12/31/1999',name:'12/31/1999'},
                    {id:'1999/12/31',name:'1999/12/31'},
                    {id:'1999/31/12',name:'1999/31/12'},
                    {id:'12 Dec, 1999',name:'12 Dec, 1999'},
                    {id:'12 December, 1999',name:'12 December, 1999'},
                    {id:'December 12, 1999',name:'December 12, 1999'}, 
                    {id:'Fri 12 Dec, 1999',name:'Fri 12 Dec, 1999'},
                    {id:'Friday 12 Dec, 1999',name:'Friday 12 Dec, 1999'},
                    {id:'Fri 12 December, 1999',name:'Fri 12 December, 1999'},
                    {id:'Friday 12 December, 1999',name:'Friday 12 December, 1999'},
                ],
                TimeFormats: [{id:'24',name:'24 Hour'},{id:'12',name:'12 Hour'}],
                InvoiceDueDateVar: [{id:'afterRaised',name:'After Raised'},{id:'beforeTravel',name:'Before Travel'}],
                logo:'',
                CurrenciesLoading:false,
                TimeZonesLoading:false,
                DistanceUnitsLoading:false,
                CitiesLoading:false,
                StatesLoading:false,
                CountriesLoading:false,
                module_name:'Company',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false,
                countries:[],
                states:[],
                cities:[],
                DistanceUnits: [],
                Currencies: [],
                TimeZones: [],
                moduleId:16,
                filteredDateFormat:[],
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
		created() {   
		},
        computed: { 
        },
		mounted() {  
            setTimeout( () => {
                this.onReady = true;
            },2000);
            this.resetForm();
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();  
		},
		methods:{   
            searchInDateFormat(event){
                setTimeout(() => {
                    if (!event.query.trim().length) {
                        this.filteredDateFormat = [...this.DateFormats];
                    }
                    else { 
                        this.filteredDateFormat = this.DateFormats.filter((format,found = false) => {
                           
                            if(format.name.toLowerCase().startsWith(event.query.toLowerCase())){
                                found = true;
                            }
                             
                            return found;
                        });
                    }
                }, 150);
            },
            addCompanyBankAccountItem(){  
                this.form.companyBankAccounts.push({'acc_title' : '', 'bank_name' : '', 'branch_code' : '', 'acc_no' : '', 'iban' : '', 'swift_code' : '' }); 
            },
             getAllRequestData(){   
                this.$axios.all([this.getTimeZones()])
                .then(() => {  
                    return this.getCurrencies();    
                })     
                .then(() => {  
                    return this.updateRecord();    
                }) 
                .then(() => {  
                    return this.getCountries();     
                }) 
                .then(() => {  
                    return this.getDistanceUnits();    
                }) 
                .then(() => {  
                    return this.getStates();    
                }) 
                .then(() => {  
                    return this.getCities();  
                })  
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },

            async getCurrencies(){  
                this.CurrenciesLoading = true; 
                var Vthis = this;  
                await Vthis.axios.post('getAllCurrencies',{dropdown:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){  
                        this.Currencies = response.data; 
                    } 
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.CurrenciesLoading = false; 
                }) 
                 
            },
            async getTimeZones(){  
                this.TimeZonesLoading = true; 
                var Vthis = this;  
                await Vthis.axios.post('getAllTimeZones',{dropdown:true})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.TimeZones = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.TimeZonesLoading = false; 
                }) 
                 
            },
             async getDistanceUnits(){  
                this.DistanceUnitsLoading = true; 
                var Vthis = this;  
                await Vthis.axios.post('getAllDistanceUnits',{dropdown:true})
                .then( (response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){   
                        this.DistanceUnits = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.DistanceUnitsLoading = false; 
                }) 
                 
            },
             async getCountries(){  
                this.CountriesLoading = true; 
                var Vthis = this;  
                await Vthis.axios.post('getAllCountries',{dropdown:true})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.countries = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.CountriesLoading = false; 
                }) 
                 
            },

            async getStates($event=''){  
               
                var country = 0;
                if(this.recId){ 
                    country = this.form.country;
                }
                else if($event.value){
                     country = $event.value;
                }
                
                if(country){ 
                    this.StatesLoading = true;
                    var Vthis = this;  
                    await Vthis.axios.post('getAllStates',{country:country,dropdown:true})
                    .then( (response) => { 
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){   
                            this.states = response.data;   
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => {  
                        this.StatesLoading = false;
                    }) 
                }
            },

            async getCities($event=''){  

                var state = 0;
                if(this.recId){ 
                    state = this.form.state;
                }
                else if($event.value){
                     state = $event.value;
                }
                if(state){ 
                    this.CitiesLoading = true;
                    var Vthis = this;  
                    await Vthis.axios.post('getAllCities',{state:state,dropdown:true})
                    .then( (response) => {   
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            this.cities = response.data;  
                        } 
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => {  
                        this.CitiesLoading = false;
                    }) 
                }
            },

            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await this.form
                .post(Vthis.$baseurl+'api/saveCompany', Vthis.form)
                .then((response) => { 
                    let responseStatus = this.printResponseResult(response); 
                    if(responseStatus == 200){ 
                        // this.getRecords();
                        if(this.saveAndClose){   
                            this.saveAndClose = false;
                            this.closeForm();
                        }     
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
            myUploader(event){   
                this.form.logo = event.files;
            },  
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllCompanies',{id:this.recId})
                    .then( (response) => { 
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){    
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                             console.log(response.data[0]);
                            Vthis.form.fill(this.replaceNullValues(response.data[0]));
                            Vthis.logo = false;
                            if(response.data[0].logo){
                                Vthis.logo = Vthis.filesUrl+"companies/"+response.data[0].logo;
                            }  

                            if(response.data[0].company_currency_ids){
                                response.data[0].company_currency_ids = response.data[0].company_currency_ids.toString();
                                var selectedCurrencies = response.data[0].company_currency_ids.split(','); 
                                if(selectedCurrencies.length > 0){ 
                                    var frr = [];
                                    this.Currencies.forEach( (currency) => {  
                                        if(selectedCurrencies.includes(currency.id.toString())){ 
                                            frr.push(currency.id);
                                        } 
                                    });
                                    Vthis.form.currency = frr;
                                }
                            } 
                        } 
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
            async removeLogo(){ 
                var Vthis = this;  
                await Vthis.axios.post('deleteCompanyLogo',{id:this.form.id})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        Vthis.showMessage('Deleted Successfully','success'); 
                        Vthis.logo = false;
                    } 
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {   
                }) 
            },
            confirmFileDeletion($event){
                this.$confirm.require({
                    target: $event.currentTarget,
                    message: 'Are you sure you want to proceed?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.removeLogo();
                    },
                    reject: () => {
                        
                    }
                });
            }
		},
        watch : {
             
        }
}
</script>
 
